<template>
<div>
  <a-modal :width="700" :visible="modal.visible" @cancel="modal.visible = ! modal.visible" :centered="true" :footer="null">
    <div class="mt-3">
      <img style="object-fit:contain; width:100%" :src="modal.image" />
    </div>
  </a-modal>
  <div class="image-holder-box" :style="setWidth ?  'width:350px':'width:100%'" @mouseover="showOptions = true" @mouseleave="showOptions = false">
    <div class="dF aC jC" v-if="img == ''" style="height:100%; width:100%" @click="$store.commit('MEDIA_SELECT',{callback:selectedMarkerImage,type:`${mediaType}`})" >
      <div>
        <a-icon type="plus" style="color:white; font-size:30px; background-color:#ECE9F1; padding:15px; border-radius:50px" />
        <div class="mt-3">{{promptText}}</div>
      </div>
    </div>
    <div v-else class="w-full h-full relative">
      <div v-if="showOptions && type == 1" class="absolute dF aC jC" style="z-index:1000; width: 100%; height: 100%; background-color: rgba(64, 69, 76, 0.5);">
        <a-button-group>
          <!-- <a-button>Image</a-button> -->
          <a-button @click="$store.commit('MEDIA_SELECT',{callback:selectedMarkerImage,type:`${mediaType}`})"><a-icon type="edit" /></a-button>
          <a-button @click="selectedMedia.ext != 'png' && selectedMedia.ext != 'jpeg' && selectedMedia.ext != 'jpg' ? previewImage('https://ss3.nyc3.digitaloceanspaces.com/bh-prod/no-instance/no_video_a6e4ee5574.png') : previewImage(img)"><a-icon type="eye" /></a-button>
          <a-button @click="$emit('callback', '')"><a-icon type="delete" /></a-button>
        </a-button-group>
      </div>
	  <img v-if="img != ''" style="width:100%; height:100%; object-fit:cover" :src="img" />
      <img v-else-if="img != '' && (selectedMedia.ext == '.png' || selectedMedia.ext == '.jpeg' || selectedMedia.ext == '.jpg')" style="width:100%; height:100%; object-fit:cover" :src="img" />
      <div v-else-if="img != '' && (selectedMedia.ext != '.png' && selectedMedia.ext != '.jpeg' && selectedMedia.ext != '.jpg')" class="absolute dF aC jC" style="z-index:1000; width: 100%; height: 100%;">
        <div class="w-full" style="text-align: center;">
          <img class="w-1/2 h-1/2" style="object-fit:cover" :src="selectedMedia.url" />
          <div>
            {{selectedMedia.name}}
          </div>
        </div>
      </div>

    </div>
  </div>
  <div class="mt-3" :style="setWidth ?  'width:350px':'width:100%'">
    <div v-if="type == 2 && img !== ''" class="dF" style="justify-content: space-between">
      <div @click="$store.commit('MEDIA_SELECT',{callback:selectedMarkerImage,type:`${mediaType}`})" class="px-3" style="border-radius:4px; border:solid 1px #707070; cursor: pointer">{{uploadText}}</div>
      <div @click="$emit('callback', '')" class="px-3" style="border-radius:4px; border:solid 1px #707070; cursor: pointer">{{removeText}}</div>
    </div>
  </div>
</div>
</template>

<script>

import ImageBoxSelector from 'bh-mod/components/common/ImageBoxSelector'

export default {
  components:{ImageBoxSelector},
  props: {
    setWidth:{
      default:true,
      type:Boolean
    },
    type:{
      default:1,
      type:Number
    },
    img:{
      default: '',
      type: String
    },
    mediaType:{
      default:'images',
      type:String
    },
    promptText:{
      default:'Add Image',
      type:String
    },
    removeText:{
      default:'REMOVE IMAGE',
      type:String
    },
    uploadText:{
      default: 'UPLOAD IMAGE',
      type:String
    }
  },
    data() {
      return {
        showOptions: false,
        // extension:'',
        newImg: '',
        modal:{
          visible:false,
          image:''
        },
        selectedMedia:{
          name:'',
          ext:''
        }
      }
    },
    watch:{
      newImg(val){
		console.log('NEW IMAGE WATCH RAN', val)
        let self = this
        let ext = val.split('.')[val.split('.').length -1] 

        let img = document.createElement('img');
        img.onload = () => {
            console.log('width => ',img.naturalWidth);
            console.log('height => ',img.naturalHeight);
            let width = img.naturalWidth
            let height = img.naturalHeight
            if (ext !== 'svg' && (!width || !height) ) {
                self.img = ''
                return self.$message.error('Image Error. Not a Valid Image')
            }
          

            self.$emit('input',val)
            self.$emit('sized',{ext,width,height})
        }
        img.src = val;
          
      }
    },
    computed: {
        
    },
    methods: {
    previewImage(img) {
      this.modal.visible = true
      this.modal.image = img
    },
    selectedMarkerImage(url){
      this.newImg = url.url
      this.selectedMedia.name = url.name
      this.selectedMedia.ext = url.ext
      this.selectedMedia.url = 'https://ss3.nyc3.digitaloceanspaces.com/bh-prod/no-instance/no_video_a6e4ee5574.png'
      console.log('selectedmedia', this.selectedMedia)
      this.$emit('callback', this.newImg)
    },
    },
};
</script>

<style scoped>
.image-holder-box{
  height:250px;
  border-radius:4px; 
  border:dashed 1px #000;
  cursor:pointer;
}
.image-holder-box:hover{
  border-color:var(--orange);
}
</style>
